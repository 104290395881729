<template>
    <div class="body-container">
        <BannerSection title="Préstamo rápido" />
        <PerksComponent></PerksComponent>

        <div class="section-container regular-text">
            <div class="position-relative  pb-3 pb-md-0 ">
                <div class="text-container">
                    <p class="mb-0">
                        Si necesitas <b>dinero urgente</b> y te preguntas "¿dónde puedo sacar un préstamo rápido?", estás en el lugar indicado. 
                    </p>
                    <p>
                        Por eso, ofrecemos <b>préstamos rápidos</b> diseñados para adaptarse a tus necesidades, con procesos 100% en línea y sin complicaciones.
                    </p>
                    <span class="bold-text">
                        <br>
                        <h2 style="font-size: 20px;">Préstamo Rápido Online</h2>
                    </span>

                    <p>
                        En Ibancar, entendemos que a veces necesitas una <b>solución financiera inmediata</b>, por eso ofrecemos préstamos rápidos diseñados para que obtengas el dinero que necesitas en el menor tiempo posible, generalmente en 24 horas.
                    </p>
                    <p>
                        A diferencia de los préstamos bancarios tradicionales, nuestros préstamos requieren menos trámites y documentación, lo que los convierte en la opción perfecta para situaciones de emergencia.
                    </p>
                    <br>
                    <span class="bold-text">
                        <br>
                        <h2 style="font-size: 20px;">Ventajas de los préstamos rápidos:</h2>
                    </span>
                    <ul>
                        <li>
                            <p class="mb-0 bold-text">
                                Aprobación en tiempo récord:
                            </p>
                            <span>Puedes tener el dinero en tu cuenta en menos de 24 horas.</span>
                        </li>
                        <li>
                            <p class="mb-0 bold-text">
                                Menos requisitos:
                            </p>
                            <span> No necesitas papeleo complejo, el proceso es sencillo y rápido.</span>
                        </li>
                        <li>
                            <p class="mb-0 bold-text">
                                Accesibles para todos:
                            </p>
                            <span>Incluso si tienes un mal buró de crédito. </span>
                        </li>
                        <li>
                            <p class="mb-0 bold-text">
                                Flexibilidad:
                            </p>
                            <span>Puedes elegir el monto, entre $12,000 y $120,000, y los plazos según tus necesidades.</span>
                        </li>
                        <br>
                    </ul>
                    <br>

                    <span class="bold-text">
                        <h2 style="font-size: 20px;">Préstamo rápido México</h2>
                    </span>
                    <p>
                        En Ibancar, sabemos que cuando necesitas un <b>préstamo rápido</b>, buscas confianza, rapidez y flexibilidad. Por eso, somos una de las mejores alternativas en México para obtener financiamiento inmediato. Nuestro proceso es 100% digital, lo que te permite gestionar todo sin salir de casa, y nuestras condiciones están diseñadas para adaptarse perfectamente a tus necesidades.
                    </p>
                    <p>
                        Nos especializamos en ofrecer <b>una solución financiera rápida y segura</b>, siempre priorizando tu tranquilidad. Si te preguntas <b>dónde puedo obtener un préstamo rápido en México</b>, somos la alternativa perfecta para ti.
                    </p>
                    <br>
                </div>
            </div>
        </div>


        <div style="background-color: rgba(255, 165, 0, 0.04); padding: 1.5rem 0rem; margin: 1.5rem 0rem;">
            <div class="ibancar-election">
                <p>¿Por qué elegir Ibancar?</p>
            </div>
            <BeneficiosIbancarCarousel />
        </div>

        <div class="section-container regular-text">
          <div class="position-relative  pb-3 pb-md-0 ">
              <div class="text-container">
                <br>
                <span class="bold-text">
                  <h2 style="font-size: 20px;">Características de los préstamos rápidos:</h2>
                </span>
                <ul class="styled-list">
                    <li>
                        <p class="mb-0 bold-text">
                            Dinero en 24 horas:
                        </p>
                        <span>Ideal para resolver emergencias.
                        </span>
                    </li>
                    <li>
                        <p class="mb-0 bold-text">
                            Requisitos simples:
                        </p>
                        <ul>
                            <li>Tener un auto en propiedad.</li>
                            <li>Documento de identificación vigente.</li>
                            <li>Comprobante de ingresos.</li>
                            <li>Comprobante de domicilio. </li>
                        </ul>
                    </li>
                    <li>
                        <p class="mb-0 bold-text">
                            Sin pérdida de activos:
                        </p>
                        <span>En Ibancar permitimos usar tu coche como garantía sin dejar de usarlo.</span>
                    </li>
                </ul>

                <br>

                <span class="bold-text">
                    <h2 style="font-size: 20px;">¿Cómo funcionan los Préstamos Rápidos Online?</h2>
                </span>
                <p>
                    El proceso para obtener un préstamo en Ibancar es 100% digital, rápido y sencillo. Todo inicia con la <b>solicitud en línea</b>, donde únicamente necesitas proporcionar tus <b>datos personales y del vehículo</b>. Al enviar este formulario, recibirás una <b>preaprobación inmediata</b> con una estimación del monto que podemos ofrecerte, basada en la información proporcionada.
                </p>
                <p>
                    Una vez preaprobado el préstamo, realizamos un análisis detallado para validar la solicitud. En este paso es cuando se te solicitarán los <b>documentos adicionales del vehículo</b>, como la factura, y se verifica toda la información necesaria.
                </p>
                <p>
                    Después de la aprobación final, el contrato se firma de manera digital para facilitar todo el proceso sin necesidad de desplazarte. Finalmente, recibirás el dinero <b>directamente en tu cuenta en menos de 24 horas</b>, sin dejar de manejar tu auto.
                </p>
                <p>
                    Con Ibancar, <b>tu auto sigue siendo tuyo y puedes seguir usándolo mientras aprovechas el préstamo rápido y seguro</b>.
                </p>
                <br>
                <br>

              </div>
          </div>
      </div>
     
        <SolicitaloBannerS2 />
    </div>
</template>

<script>
import { useMeta } from 'vue-meta';
import BannerSection from '@/components/Landings/BannerSection.vue';
import BeneficiosIbancarCarousel from '@/components/PrestamoGarantiaAuto/BeneficiosIbancarCarousel.vue';
import SolicitaloBannerS2 from '@/components/Landings/SolicitaloBannerS2.vue';
import PerksComponent from '../components/PerksComponent.vue';


export default {
    name: 'PrestamosDeDineroRapido',
    components: {
        BannerSection,
        BeneficiosIbancarCarousel,
        SolicitaloBannerS2,
        PerksComponent
    },
    setup() {
        useMeta(
            {
                title: 'Préstamo rapido y confiable en México',
                meta: [
                    {
                        name: 'robots',
                        content: 'index'
                    },
                    {
                        name: 'description',
                        content: 'Obtén un préstamo rápido en México con Ibancar. Solicita financiamiento en línea y recibe dinero en menos de 24 horas. Sin trámites complicados, con requisitos simples y flexibilidad para elegir el monto y plazo. Ideal para emergencias, incluso con mal buró de crédito. ¡Solicita ahora tu préstamo rápido y seguro!'
                    },
                    {
                        name: 'keywords',
                        content: 'prestamo rapido - rápido prestamo - donde puedo sacar un prestamo rapido - prestamo rapido mexico - donde puedo sacar un prestamo facil y rapido'
                    }
                ],
                link: [
                    // { href: 'https://ibancar.com/prestamos-con-aval-de-coche-sin-cambiar-de-titular', rel: 'alternate', hreflang: 'es-es' },
                    { href: 'https://ibancar.mx/prestamo-rapido', rel: 'alternate', hreflang: 'es-MX' }
                ]
            }
        )
    },

    data() {
        return {
            ventajas: [
                {
                    image: 'image12.svg',
                    title: 'Ejecutivo personalizado',
                },
                {
                    image: 'solicitud-en-linea.svg',
                    title: 'Proceso simple sin salir de casa',
                },

                {
                    image: 'prestamos-48-hrs.svg',
                    title: 'Hasta $120,000 en 24H',
                },
                {
                    image: 'proceso-simple.svg',
                    title: 'Aceptamos personas en Buró.',
                }

            ]
        }
    }
}
</script>

<style scoped lang="scss">
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';


.image-container {
    text-align: center;
    margin: 3rem 0rem;

    img {
        max-height: 400px;
    }
}

.body-container {
    margin-top: 4.5rem;
    min-height: calc(100vh - 115px);
}

@include media-breakpoint-up(md) {
    .body-container {
        margin-top: 8.5rem;
    }
}

.ibancar-election {
    margin: 1rem 2rem 0rem 2rem;

    p {
        color: #004680;
        text-align: center;
        font-family: 'Montserrat-Bold';
        font-size: 1.3rem;
        font-style: normal;
        line-height: normal;
        margin: 1.5rem 0rem;
    }
}

.text-container {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    font-size: 20px;
    text-align: justify;
}

.regular-text {
    font-family: "Montserrat";
    font-size: 20px;
    line-height: 30px;
}

.title {
    font-size: 25px;
    line-height: 30px;
}

.bold-text {
    font-family: "Montserrat-Bold";
}

.text-orange {
    color: $orange;
}

.text-blue {
    color: $blue;
}

.text-black {
    color: $black;
}

.text-underline {
    text-decoration: underline;
}


.btn-solicitar {
    a {
        &:hover {
            text-decoration: none;
        }
    }
}

// Image
.image {
    bottom: 0;
    right: 0;
    width: 30%;
    z-index: -1;
}

@include media-breakpoint-down(md) {
    .text-container {
        width: 90%;
    }

    .regular-text {
        line-height: 1.5;
    }

    ul {
        padding-inline-start: 20px;
    }

    ol {
        padding-inline-start: 20px;
    }
}

@include media-breakpoint-between(md, lg) {
    .regular-text {
        font-size: 16px;
    }

    .title {
        font-size: 28px;
        line-height: 35px;
    }

    .subtitle {
        font-size: 16px;
    }
}

@include media-breakpoint-down(sm) {
    .title {
        font-size: 20px;
        line-height: 25px;
    }

    .subtitle {
        font-size: 16px;
        line-height: 20px;
    }

    .regular-text {
        font-size: 16px !important;
    }

    .section-container {
        margin-top: 1.5rem;
    }
}
</style>